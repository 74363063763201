import { mapGetters, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';
import { isNullOrWhiteSpace } from '@/utils';
import {
  Tag,
  Map,
  SearchHeader,
  Sidebar,
  GroupCard,
  Loader,
  MobileFilter,
  Advertisement,
} from '@/components';

export default {
  metaInfo: {
    title: 'Groepen - IKwatersport',
  },
  name: 'GroupSearch',
  components: {
    [Tag.name]: Tag,
    [Map.name]: Map,
    [SearchHeader.name]: SearchHeader,
    [Sidebar.name]: Sidebar,
    [GroupCard.name]: GroupCard,
    [Loader.name]: Loader,
    [MobileFilter.name]: MobileFilter,
    [Advertisement.name]: Advertisement,
  },
  props: {},
  data: () => ({
    title: 'Groepen',
    searchPlaceholder: 'Zoek een groep...',
    mobileFilterActive: false,
    possibleToUpdateUrl: false,
    page: 1,
    mapViewActive: false,
    map: {
      zoom: 8,
      points: [],
      center: [51, 3],
      showGroups: true,
    },
    created: false,
    advertisement: null,
    windowWidth: window.innerWidth,
  }),
  computed: {
    isTablet() {
      if (this.windowWidth < 769) {
        return true;
      }
      return false;
    },
    ...mapGetters('advertisement', {
      bestMatch: 'getBestmatch',
    }),
    ...mapGetters('group', {
      groups: 'getGroupList',
      markers: 'getGroupMarkers',
      isLoading: 'getLoadingState',
      buttonIsLoading: 'getButtonLoadingState',
      getGroupFilter: 'getGroupFilter',
    }),
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    ...mapActions('advertisement', {
      initialiseAdvertisementBestmatch: 'FETCH_DATA_ADVERTISEMENT_BESTMATCH',
    }),
    ...mapMutations('group', {
      clearGroupList: 'CLEAR_GROUP_LIST',
      setGroupFilterDiscipline: 'SET_GROUP_FILTER_DISCIPLINE',
      setGroupFilterQuery: 'SET_GROUP_FILTER_QUERY',
      setGroupFilterLocation: 'SET_GROUP_FILTER_LOCATION',
    }),
    ...mapActions('group', {
      getGroups: 'FETCH_DATA_GROUP_LIST',
      getGroupMarkers: 'FETCH_DATA_GROUP_MARKERS',
    }),
    ...mapActions('discipline', {
      fetchDisciplineList: 'FETCH_DATA_DISCIPLINE_LIST',
    }),
    toggleMapView() {
      this.mapViewActive = !this.mapViewActive;
      if (this.mapViewActive) {
        this.queryGroupMarkers();
      } else {
        this.clearGroupList();
        this.page = 1;
        const filter = _.clone(this.getGroupFilter);
        filter.page = 1;
        this.getGroups(filter);
      }
    },
    toggleMobileFilter() {
      this.mobileFilterActive = !this.mobileFilterActive;
    },
    search(searchQuery) {
      this.setGroupFilterQuery(searchQuery);
    },
    updateRouteQuery() {
      const filter = this.getGroupFilter;
      const queryObject = {};
      if (!isNullOrWhiteSpace(filter.query)) {
        queryObject.q = filter.query;
      }

      if (filter.discipline !== 0 && filter.discipline !== null) {
        this.discipline = this.disciplineList.find((d) => d.id === filter.discipline);
        queryObject.discipline = this.discipline.name;
      }

      if (!isNullOrWhiteSpace(filter.location)) {
        queryObject.locatie = filter.location;
      }

      this.$router.replace({ query: queryObject }).catch(() => { });
    },
    updateGroupFilterFromQuery() {
      const queryObject = this.$route.query;
      if (_.isEmpty(queryObject)) {
        this.possibleToUpdateUrl = true;
        return;
      }

      if (!isNullOrWhiteSpace(queryObject.q)) {
        this.setGroupFilterQuery(queryObject.q);
      }

      if (!isNullOrWhiteSpace(queryObject.discipline)) {
        this.discipline = this.disciplineList.find((d) => d.name.includes(queryObject.discipline));
        this.setGroupFilterDiscipline(this.discipline.id);
      }

      if (!isNullOrWhiteSpace(queryObject.locatie)) {
        this.setGroupFilterLocation(queryObject.locatie);
      }
    },
    loadMoreGroups() {
      this.page += 1;

      const filter = _.clone(this.getGroupFilter);
      filter.page = this.page;
      filter.loadButton = true;
      this.getGroups(filter);
    },
    queryGroupMarkers() {
      const filter = _.clone(this.getGroupFilter);
      filter.pageSize = 100000;
      filter.page = 1;
      this.getGroupMarkers(filter);
    },
    async getGroupFilterWatchFunction(updatedGroupFilter) {
      this.clearGroupList();
      this.page = 1;

      const filter = updatedGroupFilter;
      filter.page = this.page;

      if (this.mapViewActive) {
        this.queryGroupMarkers();
      } else {
        this.getGroups(filter);
      }

      this.updateRouteQuery();
    },
  },
  created() {
    const params = {
      tags: [],
    };
    this.initialiseAdvertisementBestmatch(params)
      .then(() => {
        this.advertisement = this.bestMatch;
      });

    this.created = true;
    this.fetchDisciplineList()
      .then(async () => {
        this.updateGroupFilterFromQuery();
        await this.getGroupFilterWatchFunction(this.getGroupFilter);
        this.created = false;
      });
  },
  watch: {
    $route() {
      this.updateRouteQuery();
    },
    getGroupFilter: {
      handler(updatedGroupFilter) {
        if (!this.created) { this.getGroupFilterWatchFunction(updatedGroupFilter); }
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
